.loading {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background: var(--basethree);
    display: block;
}

.loading.hide {
    display: none;
}

.box{}

.imgLoad {
    position: absolute;
    top: 40%;
    text-align: center;
    left: 50%;
    font-size: 18px;
    font-weight: 100;
    width: auto;
    transform: translate(-50%, -50%)
}

.progress {
    height: 2px;
    display: block;
    width: 30%;
    background-color: var(--transone);
    border-radius: 2px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
}

.progress .line {
    background-color: var(--basetwo)
}

.progress .line:before {
    content: '';
    position: absolute;
    background-color: inherit;
    top: 0;
    left: 0;
    bottom: 0;
    will-change: left, right;
    animation: line 2.1s cubic-bezier(.65, .815, .735, .395) infinite
}

.progress .line:after {
    content: '';
    position: absolute;
    background-color: var(--basetwo);
    top: 0;
    left: 0;
    bottom: 0;
    will-change: left, right;
    animation: lines 2.1s cubic-bezier(.165, .84, .44, 1) infinite;
    animation-delay: 1.15s
}

@keyframes line {
    0% {
        left: -35%;
        right: 100%
    }

    60% {
        left: 100%;
        right: -90%
    }

    100% {
        left: 100%;
        right: -90%
    }
}

@keyframes lines {
    0% {
        left: -200%;
        right: 100%
    }

    60% {
        left: 107%;
        right: -8%
    }

    100% {
        left: 107%;
        right: -8%
    }
}