:root {
    --colorgradientone: #ff5a00;
    --baseone: #fff;
    --basetwo: #ff00fb;
    --basethree: #0a0e0c;
    --basefour: #12ff00;
    --basefive: #00e0ff;
    --basesix: #ff8800;
    --baseseven: #c20000;
    --transone: rgba(255, 255, 255, .5);
    --transtwo: rgba(253, 0, 250, 0.5);
    --body: #0a0e0c
}